
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.chat {
  position: relative;
  margin: 32px 0 0 0;
  border: 1px solid rgba(232, 232, 238, 1);
  border-radius: 8px;
  overflow: hidden;

  @include for-size(phone-portrait-down) {
    margin: 32px -20px 0;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 8px 8px 0 0;
  }

  &--draggable {
    border-color: #855CE5;
  }
  &__header {
    padding: 18px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid rgba(232, 232, 238, 1);
    @include for-size(phone-portrait-down) {
      padding: 18px 16px;
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
  }
  &__body {
    padding: 24px;
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
    @include for-size(phone-portrait-down) {
      padding: 16px;
    }
  }
  &__footer {
    padding: 16px 24px;
    background: rgba(244, 244, 245, 1);
    &-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin: 15px 0 0 0;
      &:first-child {
        margin: 0;
      }
      @include for-size(phone-portrait-down) {
        position: relative;
        display: block;
      }
    }
    ::v-deep .esmp-input .esmp-input-element {
      background-color: #fff;
    }
  }
  &__input {
    width: 100%;
    @include for-size(phone-portrait-down) {
      position: relative;
      display: block;
      ::v-deep .esmp-input .esmp-input-element {
        background-color: #fff;
        padding-right: 90px;
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0 0 24px;
    svg {
      cursor: pointer;
    }
    @include for-size(phone-portrait-down) {
      position: absolute;
      top: -2px;
      right: 12px;
    }
  }
  &__upload-button {
    width: 24px;
    height: 24px;
  }
  &__send-button {
    margin: 0 0 0 10px;
  }
  &__message {
    margin: 15px 0 0 0;
    &:first-child {
      margin: 0;
    }
  }
}
.message {
  width: 100%;
  padding: 0 10% 0 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  &__text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
  &__author {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #2F9AFF;
  }
  &__author-avatar {
    margin: 0 10px 0 0;
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    @include for-size(phone-portrait-down) {
      width: 32px;
      height: 32px;
    }
  }
  &_owner {
    padding: 0 0 0 10%;
    justify-content: flex-end;
    .message__author-avatar {
      margin: 0 0 0 10px;
    }
    .message__author {
      color: #9466FF;
    }
  }
  &__body {
    position: relative;
    padding: 8px 14px;
    border-radius: 8px;
    background: rgba(244, 244, 245, 1);
  }
  &__created {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: rgba(16, 24, 40, 0.6);
  }
  &__corner {
    position: absolute;
    bottom: 0;
    width: 20px;
    height: 22px;
    &_left {
      left: -12px;
    }
    &_right {
      right: -12px;
    }
  }
}
